<template>
  <div>
    <div>
      <loader :isLoading="isLoaded"></loader>
    </div>
    <button
      class="btn btn-default btn-sm mt-4 px-5"
      @click="$router.go(-1)"
    >
      Back
    </button>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-12">
            <div
              class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0"
              id="BusinessInfo"
            >
              <div class="card-body px-lg-5 py-lg-3">
                <div class="row">
                  <div class="col-md-12">
                    <h3>Resource Details</h3>
                    <hr class="my-0 mb-3" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <strong
                      ><em class="fas fa-signature mr-2"></em> Title:
                    </strong>
                    <span>{{ data.title }}</span>
                    <br />
                    <br />
                  </div>
                  <div class="col-md-12" v-if="data.link_label">
                    <strong
                      ><em class="fas fa-signature mr-2"></em> Link label:
                    </strong>
                    <span>{{ data.link_label }}</span>
                    <br />
                    <br />
                  </div>
                  <div class="col-md-12" v-if="data.link_url">
                    <strong
                      ><em class="fas fa-signature mr-2"></em> Link url:
                    </strong>
                    <span>{{ data.link_url }}</span>
                    <br />
                    <br />
                  </div>
                  <div class="col-md-12">
                    <strong
                      ><em class="fas fa-clock mr-2"></em> Date Added:
                    </strong>
                    <span>{{ $date_format.date_only(data.doneAt) }}</span>
                  </div>
                  <br />
                  <br />
                  <div class="col-md-12">
                    <strong
                      ><em class="fas fa-info-circle mr-2"></em> Content:
                    </strong>
                    <p class="text-muted p-2" v-html="data.content"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div
          class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0"
          id="BusinessInfo"
        >
          <div class="card-body px-lg-5 py-lg-3">
            <div class="row">
              <div class="col-md-12">
                <h3>Actions</h3>
                <hr class="my-0 mb-3" />
              </div>
            </div>
            <div class="row mb-2" v-if="$guards.is_super_admin()">
              <div class="col-md-8"><strong>Visible on public</strong></div>
              <div class="col-md-4">
                <label class="custom-toggle">
                  <input
                    id="change_visibility"
                    type="checkbox"
                    v-model="visible"
                    @change="visibility_fn"
                  />
                  <span
                    class="custom-toggle-slider rounded-circle bg-default"
                    data-label-off="No"
                    data-label-on="Yes"
                  ></span>
                </label>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12">
                <router-link
                  type="button"
                  class="btn btn-default btn-sm btn-block w-100 text-white"
                  :to="{
                    name: 'ResourceUploadImage',
                    params: { uuid: $route.params.uuid },
                  }"
                >
                  Manage Image
                </router-link>
              </div>
            </div>

            <div class="row mb-2">
              <div class="col-md-12">
                <button
                  v-if="$guards.is_super_admin()"
                  type="button"
                  class="btn btn-default btn-sm btn-block w-100"
                  @click="delete_resource_clicked"
                >
                  Delete Resource
                </button>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-md-12">
                <router-link
                  v-if="$guards.is_super_admin()"
                  type="button"
                  class="btn btn-default btn-sm btn-block w-100 text-white"
                  :to="{
                    name: 'updateResource',
                    params: { uuid: $route.params.uuid },
                  }"
                >
                  Update Resource
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { alert } from "../../utils/alertUtils";
import Loader from "../../components/Loader";
export default {
  component: {
    loader: Loader,
  },
  data() {
    return {
      isLoaded: false,
      data: {},
      visible: false,
    };
  },
  methods: {
    delete_resource: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}`;
      axios
        .delete(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("Resource has been deleted successfully");
          this.$router.push({ name: "Resources" });
        })
        .catch(() => {
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },
    delete_resource_clicked: function () {
      alert.promptDelete(this.delete_resource);
    },
    get_resource: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          this.data.title = res.data.title;
          this.data.content = res.data.content;
          this.data.doneAt = res.data.doneAt;
          this.visible = res.data.visibility_status;
          this.isLoaded = true;
        })
        .catch(() => {
          this.isLoaded = false;
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },

    visibility_fn: function (e) {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}/change_visibility`;
      axios
        .put(
          url,
          { status: e.target.checked },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("Resource visibility updated successfully");
        })
        .catch((error) => {
          this.visible = !this.visible;
          console.log(error.response);
          alert.error(
            "An error occured in changing visibility please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },
  },
  created() {
    this.get_resource();
  },
};
</script>
<style lang="css" scoped>
.custom-toggle input:checked + .custom-toggle-slider:after {
  color: #e9ecef;
}
.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #e9ecef;
}
.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #e9ecef;
}
</style>